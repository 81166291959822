@import "../node_modules/modern-normalize/modern-normalize.css";

@stylex;

:root {
  --modal-height: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  font-family:
    Hallow Moderat,
    sans-serif;
  height: 100%;
}

* {
  transition-property:
    background, color, letter-spacing, word-spacing, border-color, outline-color;
  transition-duration: 200ms, 50ms, 50ms, 50ms;
  transition-timing-function: ease-in;
}

menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Reset Semantic HTML */

a:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

/* Sans-serif variants */

@font-face {
  font-family: Hallow Moderat;
  src:
    url("assets/fonts/Hallow-Moderat-Regular.woff2") format("woff2"),
    url("assets/fonts/Hallow-Moderat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: sans-fallback;
  src: local("Arial");
  font-weight: 400;
  font-style: normal;
  size-adjust: 97%;
}

@font-face {
  font-family: Hallow Moderat;
  src:
    url("assets/fonts/Hallow-Moderat-Semibold.woff2") format("woff2"),
    url("assets/fonts/Hallow-Moderat-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: sans-fallback;
  src: local("Arial");
  font-weight: 600;
  font-style: normal;
  size-adjust: 105%;
}

@font-face {
  font-family: Hallow Moderat;
  src:
    url("assets/fonts/Hallow-Moderat-Bold.woff2") format("woff2"),
    url("assets/fonts/Hallow-Moderat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: sans-fallback;
  src: local("Arial");
  font-weight: 700;
  font-style: normal;
  size-adjust: 105%;
}

@font-face {
  font-family: Hallow Moderat;
  src:
    url("assets/fonts/Hallow-Moderat-Extrabold.woff2") format("woff2"),
    url("assets/fonts/Hallow-Moderat-Extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: sans-fallback;
  src: local("Arial");
  font-weight: 800;
  font-style: normal;
  size-adjust: 105%;
}

/* Serif variants */

@font-face {
  font-family: Hallow Moderat Serif;
  src:
    url("assets/fonts/Hallow-Moderat-Serif-Regular.woff2") format("woff2"),
    url("assets/fonts/Hallow-Moderat-Serif-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: serif-fallback;
  src: local("Times New Roman");
  font-weight: 400;
  font-style: normal;
  size-adjust: 105.1%;
}

@font-face {
  font-family: Hallow Moderat Serif;
  src:
    url("assets/fonts/Hallow-Moderat-Serif-Regular-Italic.woff2")
      format("woff2"),
    url("assets/fonts/Hallow-Moderat-Serif-Regular-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: serif-fallback;
  src: local("Times New Roman");
  font-weight: 400;
  font-style: italic;
  size-adjust: 102%;
}

@font-face {
  font-family: Hallow Moderat Serif;
  src:
    url("assets/fonts/Hallow-Moderat-Serif-Semibold.woff2") format("woff2"),
    url("assets/fonts/Hallow-Moderat-Serif-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: serif-fallback;
  src: local("Times New Roman");
  font-weight: 600;
  font-style: normal;
  size-adjust: 110%;
}

@font-face {
  font-family: Hallow Moderat Serif;
  src:
    url("assets/fonts/Hallow-Moderat-Serif-Semibold-Italic.woff2")
      format("woff2"),
    url("assets/fonts/Hallow-Moderat-Serif-Semibold-Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: serif-fallback;
  src: local("Times New Roman");
  font-weight: 600;
  font-style: italic;
  size-adjust: 110%;
}

.copyright {
  /* override Bible CSS */
  height: auto !important;
  white-space: normal !important;
}

/* WebKit and Blink have default values for these elements (Firefox doesn't apparently). By specifying inherit,
they'll either be normal (no change) or whatever we set at the top level through user accessibility settings. */
input,
textarea,
select,
button {
  letter-spacing: inherit;
  word-spacing: inherit;
}

.paypal-buttons,
.paypal-buttons iframe {
  z-index: 0 !important;
}

/* ScrollArea styles */

[data-scroll-area-table-margin-auto] > div {
  margin: auto;
}

/* Video subtitle styles */
/* This is a workaround for Safari. Most browsers make pseudo-elements a feature of their parent
   (real) element, but Safari creates a shadow DOM where a pseudo-element is marked up like
       <div pseudo="cue">Subtitles here</div>
   leading to a situation where regular browsers let you select the cue pseudo-element with, say,
   video::cue or .videoClass::cue, but in Safari, those selectors don't actually select anything,
   because the "true" pseudo-element is a div inside the shadow DOM that neither is a <video> element
   nor does it have the .videoClass class. The ONLY selector that validly selects the ::cue pseudo-
   element in Safari is this generic one. */
::cue {
  font-family:
    Hallow Moderat,
    sans-serif;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 3cqmin;
}
